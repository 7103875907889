import { PAGES } from '../elements/gobp-cells-fs-controller/constants';
import { AppRoutes, pathsToHideSidebar } from './routes';

((function() {
  'use strict';

  const appContainer = document.querySelector('.app-container');
  const appContent = document.querySelector('.app-container__content');

  if (appContainer) {
    appContainer.addEventListener('sidenav-collapse-changed', function() {
      this.classList.toggle('sidenav-collapsed');
    });

    const onActiveCurrentIsolatedPageChanged = function(e) {
      const currentIsolatedPage = e && e.detail;
      const currentTarget = e && e.target.name;
      appContainer.dispatchEvent(new CustomEvent('get-url-hash', {detail: currentTarget, bubbles: true}));
      if (pathsToHideSidebar.indexOf(currentIsolatedPage) > -1) {
        appContainer.classList.add('sidenav-hidden');
      } else {
        appContainer.classList.remove('sidenav-hidden');
      }
    };
    onActiveCurrentIsolatedPageChanged();

    window.addEventListener('current-isolated-page', onActiveCurrentIsolatedPageChanged);
    window.addEventListener('hashchange', () => {
      if (window.location.hash === '#!/') {
        setTimeout(() => appContainer.classList.remove('sidenav-hidden'), 500);
      }
    });
  }

  window.CellsPolymer.start({
    routes: AppRoutes,
    binding: 'ui',

    onRender: function onrender(template, fixedComponents) {
      if (!template.parentNode) {
        document.getElementById(this.mainNode).appendChild(template);
      }

      window.I18nMsg = window.I18nMsg || {};
      window.I18nMsg.unFlattened = true;

      var fixedZoneNames = ['fixed__header', 'fixed__loader'];
      var empty = fixedZoneNames.map(fixedZoneName => document.getElementById(fixedZoneName)).reduce((acc, next) => acc && next.childElementCount === 0, true);

      // If this template has fixed components place them in the fixed zones
      if (fixedComponents && empty) {
        fixedComponents.forEach(function(component) {
          document.getElementById(component.zone).appendChild(component.node);
        });
      }

      if (appContent) {
        appContent.scrollTop = 0;
      }

      /* Enable load MFE origins with novulcanize or vulcanize urls and BO in dist mode */
      window.AppConfig.devmode = false;
    },
    interceptor: (navigation) => {
      return {
        intercept: navigation.to.page === PAGES.HOME,
        redirect: {
          page: PAGES.ORGANIZATIONS_LABEL,
          params: {}
        }
      };
    }
  });
})());
