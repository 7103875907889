// FS_USERS_APPLICATIONS_RESPONSE: Cells channel to send users-applications functional solution data
// FS_USERS_APPLICATIONS_REQUEST: Cells channel to recieve a request from users-applications functional solution and then send data

export const CHANNELS = {
  FS_USERS_APPLICATIONS_RESPONSE: 'ch_global_btge_users_application_solution_response',
  FS_USERS_APPLICATIONS_REQUEST: 'ch_global_btge_sf_init_state_request',
  INTERNAL_GO_TO_APPLICATION_DETAIL: 'ch_global_btge_fs_application_detail',
  INTERNAL_GO_TO_APPLICATION_NEW: 'ch_global_btge_fs_new_applications',
  FS_ERROR_CONFIG: 'ch-global-config-error-change',
  FS_ERROR_BTN_CLICK: 'ch-global-error-button-click',
  FS_ERROR_LINK_CLICK: 'ch-global-error-link-click',
  FS_SETUP_API_NAVIGATION: 'ch_global_btge_fs_setup_api_navigation',
  FS_TERMS_AND_CONDITIONS_NAVIGATION: 'ch_global_btge_fs_terms_and_conditions_navigation',
  FS_USERS_BACKOFFICE_REQUEST_NEW: 'ch_global_bbva_btge_assignments_users_capabilities_solution_new_page_init_data_request',
  FS_USERS_BACKOFFICE_RESPONSE_NEW: 'ch_global_bbva_btge_assignments_users_capabilities_solution_new_page_init_data_response',
};

export const PAGES = {
  HOME: 'home',
  INFO: 'info',
  ORGANIZATIONS_LABEL: 'bbva-btge-organizations-management-solution-list',
  APPLICATIONS_LIST_LABEL: 'bbva-btge-users-application-solution-applications',
  APPLICATION_NEW_LABEL: 'bbva-btge-users-application-solution-application-new',
  APPLICATION_DETAILS_LABEL: 'bbva-btge-users-application-solution-application-detail',
  ERROR_LABEL: 'bbva-btge-error-solution-generic-service',
};

export const SOLUTIONS = {
  SET_API: 'bbva-btge-setup-api-solution',
  TERMS_AND_CONDITIONS: 'bbva-btge-terms-and-conditions-solution-list',
  USERS_BO: 'bbva-btge-assignments-users-capabilities-solution'
};
